// @mui
import { Alert, Stack, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
// hooks
// layouts
import LoginLayout from "../../layouts/login";
//
import AuthLoginForm from "./AuthLoginForm";
import { PATH_AUTH } from "src/routes/paths";

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
        <Typography variant="h4">Sign in</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>
          <Link
            to={PATH_AUTH.register}
            component={RouterLink}
            variant="subtitle2"
          >
            Create an account
          </Link>
        </Stack>
      </Stack>

      {/* <Alert severity="info" sx={{ mb: 3 }}>
        Use email : <strong>testaccount@rabill.com</strong> / password :
        <strong> demo1234</strong>
      </Alert> */}

      <AuthLoginForm />
    </LoginLayout>
  );
}
