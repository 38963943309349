import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Upload from "src/components/upload";
import Label from "src/components/label";
import axiosInstance from "src/utils/axios";
import { PATH_DASHBOARD } from "src/routes/paths";
import { CheckCircle, Circle } from "@mui/icons-material";

export const UploadDialog = ({ open, code, setUploadDialogToggle }) => {
  const [file, setFile] = useState(null);
  const [isProcessing, setProcessing] = useState(false);
  const [importError, setImportError] = useState(null);
  const [uploaded, setUploaded] = useState(false);

  const history = useNavigate();
  const handleUploadDialogClose = () => {
    setUploadDialogToggle(false);
    setFile(null);
    setProcessing(false);
    setImportError(null);
    setUploaded(false);
  };
  const fileTypes = {
    "application/*": [".xls", ".xlsx"],
    "text/*": [".csv"],
  };
  const handleUploadCancel = () => {
    setFile(null);
    setProcessing(false);
    handleUploadDialogClose();
  };
  const handleProcessing = () => {
    setProcessing(true);
  };
  const handleUploadConfirm = () => {
    handleUploadDialogClose();
    history(PATH_DASHBOARD.projects.boq.replace(":id", code));
  };

  const handleErrorRetry = () => {
    setImportError(null);
    setProcessing(false);
  };

  const handleUploaded = () => {
    setProcessing(false);
    setImportError(null);
    setUploaded(true);
  };

  const handleDropSingleFile = useCallback(
    async (acceptedFiles) => {
      try {
        const file = acceptedFiles[0];
        console.log(file);
        if (file) {
          setFile(
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );
          setProcessing(true);
          const formData = new FormData();
          formData.append("boq", file);
          console.log(code);
          const response = await axiosInstance.post(
            `api/boq/upload/${code}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log(response.data);
          setProcessing(false);
          handleUploaded();
          setTimeout(handleUploadConfirm, 1000);
        }
      } catch (error) {
        setImportError(error);
        console.log(error);
      }
    },
    [code]
  );

  const theme = useTheme();
  const BulletListItem = ({ children }) => {
    return (
      <ListItem dense disableGutters disablePadding>
        <ListItemIcon>
          <Circle color="text" sx={{ width: "0.25em" }} />
        </ListItemIcon>
        <ListItemText>{children}</ListItemText>
      </ListItem>
    );
  };
  return (
    <Dialog
      // sx={{ height: '75%', width: { xl: '75%', lg: '80%' } }}
      maxWidth={"lg"}
      open={open}
      onClose={handleUploadDialogClose}
    >
      <DialogTitle>Upload BoQ {code}</DialogTitle>
      <DialogContent>
        {uploaded === true ? (
          <Box
            sx={{
              width: "50vw",
              height: "40%",
              pb: "1em",
              color: "text.primary",
            }}
          >
            <Label
              color="success"
              sx={{
                padding: "1.5em 1em",
                justifyContent: "flex-start",
                textAlign: "left",
                width: "100%",
                color: "text.primary",
              }}
              startIcon={<CheckCircle color="success" />}
            >
              BOQ Upload Sucessful
            </Label>
            {/* <Typography variant="body2" py={2}>
              32 rows uploaded successfully
            </Typography> */}
          </Box>
        ) : importError ? (
          <Box
            sx={{
              width: "50vw",
              height: "40%",
              p: "3em",
            }}
          >
            <Label
              color="error"
              sx={{
                padding: "1.5em 1em",
                justifyContent: "flex-start",
                textAlign: "left",
                width: "100%",
                color: "text.primary",
              }}
            >
              Invalid Bill of Quantities (BOQ)
            </Label>
            <Typography variant="body2" pt={3} gutterBottom>
              {importError?.message}
            </Typography>
          </Box>
        ) : isProcessing === false ? (
          <Upload
            onDrop={handleDropSingleFile}
            onDropAccepted={handleProcessing}
            accept={fileTypes}
          />
        ) : (
          <Box
            sx={{
              width: "50vw",
              height: "40%",
              display: "flex",
              flexDirection: "column",
              rowGap: "1em",
              p: "3em",
              backgroundColor: theme.palette.grey["200"],
              borderRadius: "1.5em",
              borderWidth: "1px",
              borderStyle: "dashed",
              borderColor: theme.palette.divider,
              color: "secondary.darker",
            }}
          >
            <Typography variant="h6" color="black">
              Processing uploaded file
            </Typography>
            <LinearProgress
              color="inherit"
              sx={{ height: "0.5em", width: "100%" }}
              variant="determinate"
              value={55}
            />
          </Box>
        )}
      </DialogContent>
      {!uploaded && (
        <DialogActions>
          <Button
            variant="contained"
            onClick={importError ? handleErrorRetry : handleUploadConfirm}
            disabled={importError === null}
          >
            {importError ? "Try Again" : "Confirm"}
          </Button>
          <Button variant="outlined" onClick={handleUploadCancel}>
            Cancel
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
