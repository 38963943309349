import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config";
//
import {
  Page404,
  Projects,
  PageTwo,
  PageSix,
  PageFour,
  PageFive,
  LoginPage,
  PageThree,
  CreateProject,
  BoQ,
  MeasurementBook,
  BillSummary,
  CoP,
  Abstract,
} from "./elements";
import RegisterPage from "src/pages/RegisterPage";
import Users from "src/pages/users/Users";
import RoleBasedGuard from "src/auth/RoleBasedGuard";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "/register",
      element: (
        <GuestGuard>
          <RegisterPage />
        </GuestGuard>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "two", element: <PageTwo /> },
        { path: "three", element: <PageThree /> },
        {
          path: "users",
          element: (
            <RoleBasedGuard roles={["admin"]} hasContent>
              <Users />
            </RoleBasedGuard>
          ),
        },
        {
          path: "projects",
          children: [
            {
              element: <Projects />,
              index: true,
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard roles={["admin", "Manager"]} hasContent>
                  <CreateProject />
                </RoleBasedGuard>
              ),
            },
            {
              path: "edit/:id",
              element: (
                <RoleBasedGuard roles={["admin", "Manager"]} hasContent>
                  <CreateProject />{" "}
                </RoleBasedGuard>
              ),
            },
            { path: "view/:id", element: <CreateProject /> },
            { path: "boq/:id", element: <BoQ /> },
            { path: "measurement-book/:id", element: <MeasurementBook /> },
            { path: "bill-summary/:id", element: <BillSummary /> },
            { path: "cop/:id", element: <CoP /> },
            { path: "abstract/:id", element: <Abstract /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
