// @mui
import { Stack, Box } from "@mui/material";
// config
import { NAV } from "../../../config";
// utils
import { hideScrollbarX } from "../../../utils/cssStyles";
// components
import Logo from "../../../components/logo";
import { NavSectionMini } from "../../../components/nav-section";
//
import navConfig from "./config";
import { useEffect } from "react";
import axiosInstance from "src/utils/axios";
import { useState } from "react";
import { useLocation } from "react-router";

// ----------------------------------------------------------------------

export default function NavMini() {
  const [recentProjects, setRecentProjects] = useState([]);
  const location = useLocation();

  const getRecent = async () => {
    const response = await axiosInstance.get("/api/projects/getRecent");
    setRecentProjects(response.data);
    console.log(response.data);
  };

  useEffect(() => {
    getRecent();
  }, [location.pathname]);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: "fixed",
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <Logo sx={{ mx: "auto", my: 2 }} />

        <NavSectionMini data={navConfig(recentProjects)} />
      </Stack>
    </Box>
  );
}
