import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import Image from '../image/Image'

export default function MyDropzone(props) {
    const theme = useTheme()
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        ...props
    })

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {
                <Box
                    sx={{
                        display: 'flex',
                        gap: '3.5em',
                        p: '3em',
                        backgroundColor: theme.palette.grey['200'],
                        borderRadius: '1.5em',
                        borderWidth: '1px',
                        borderStyle: 'dashed',
                        borderColor: isDragActive ? theme.palette.primary['main'] : theme.palette.divider
                    }}>
                    <Image
                        alt="Upload Image"
                        src='\assets\illustrations\illustration_upload.svg'
                        sx={{ flex: 2 }}
                    />
                    <Box sx={{ flex: 3, display: 'flex', p: '1em', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                        <Typography variant="h4">Select Files</Typography>
                        <Typography variant='body1'>Drop files here or click browse here through your machine</Typography>
                    </Box>
                </Box>
            }
        </div>
    )
}