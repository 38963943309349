import { Suspense, lazy } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import("../pages/LoginPage")));
export const RegisterPage = Loadable(
  lazy(() => import("../pages/RegisterPage"))
);

export const PageTwo = Loadable(lazy(() => import("../pages/PageTwo")));
export const PageThree = Loadable(lazy(() => import("../pages/PageThree")));
export const PageFour = Loadable(lazy(() => import("../pages/PageFour")));
export const PageFive = Loadable(lazy(() => import("../pages/PageFive")));
export const PageSix = Loadable(lazy(() => import("../pages/PageSix")));
export const CreateProject = Loadable(
  lazy(() => import("../pages/projects/CreateProject"))
);
export const Projects = Loadable(
  lazy(() => import("../pages/projects/Projects"))
);
export const BoQ = Loadable(lazy(() => import("../pages/projects/BoQ")));
export const MeasurementBook = Loadable(
  lazy(() => import("../pages/projects/MeasurementBook"))
);
export const BillSummary = Loadable(
  lazy(() => import("../pages/projects/BillSummary"))
);

export const CoP = Loadable(
  lazy(() => import("../pages/projects/CertificateofPayments"))
);
export const Abstract = Loadable(
  lazy(() => import("../pages/projects/Abstract"))
);
export const Users = Loadable(lazy(() => import("../pages/users/Users")));

export const Page404 = Loadable(lazy(() => import("../pages/Page404")));
