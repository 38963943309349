import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "projects",
  initialState: {
    actionType: "",
    loading: false,
    error: null,
    projects: [],
    ongoing: 0,
    completed: 0,
    currentProject: null,
  },
  reducers: {
    fetchProjectsStart: (state) => {
      state.projects = [];
      state.actionType = "fetch";
      state.error = null;
      state.loading = true;
    },
    fetchProjectsDone: (state, action) => {
      state.loading = false;
      state.projects = action.payload.projects;
      state.completed = action.payload.completed;
      state.ongoing = action.payload.ongoing;
    },
    fetchProjectsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createProjectStart: (state) => {
      state.error = null;
      state.actionType = "create";
      state.loading = true;
    },
    createProjectDone: (state, action) => {
      state.loading = false;
      state.projects.push(action.payload);
    },
    createProjectError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setCurrentProjectStart: (state) => {
      state.currentProject = null;
      state.loading = true;
      state.error = null;
      state.actionType = "fetchSingle";
    },
    setCurrentProjectDone: (state, action) => {
      state.currentProject = action.payload;
      state.loading = false;
    },
    setCurrentProjectError: (state, action) => {
      state.currentProject = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchProjectsStart,
  fetchProjectsDone,
  fetchProjectsError,
  createProjectStart,
  createProjectDone,
  createProjectError,
  setCurrentProjectStart,
  setCurrentProjectDone,
  setCurrentProjectError,
} = projectSlice.actions;

export default projectSlice.reducer;
