import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { StatusButton } from "src/common/StatusButton";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Header } from "src/common/NavHeader";
import Label from "src/components/label/Label";
import { useSettingsContext } from "src/components/settings";
import {
  TableHeadCustom,
  TablePaginationCustom,
  useTable,
} from "src/components/table";
import customShadows from "src/theme/customShadows";
import axiosInstance from "src/utils/axios";
import { useSnackbar } from "src/components/snackbar";
import ManageAccessDialog from "src/common/Users/ManageAccessDialog";

const ProjectChips = ({ projects }) => {
  const length = projects.length;
  const [open, setOpen] = useState(false);
  if (length === 0) return "Not assigned";
  else if (length > 3)
    return (
      <>
        {projects
          .slice(0, 3)
          .map((project) => project.name)
          .join(", ")}
        <Button
          size="small"
          variant="outlined"
          color="primary"
          sx={{ mx: 1, my: 1 }}
          onClick={() => setOpen(true)}
        >
          {`+ ${length - 3} more`}
        </Button>

        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
          <DialogTitle>All Projects</DialogTitle>
          <DialogContent>
            {projects.map((project) => (
              <li key={project.id}>{project.name}</li>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  else return projects.map((project) => project.name).join(", ");
};

export default function Users() {
  // hooks
  const { themeStretch } = useSettingsContext();
  const {
    selected,
    rowsPerPage,
    page,
    onSelectAllRows,
    onSelectRow,
    onChangePage,
    setPage,
    onChangeRowsPerPage,
  } = useTable();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  // states
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [pending, setPending] = useState(0);
  const [approved, setApproved] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [search, setSearch] = useState("");
  const [length, setLength] = useState(0);
  const [openManage, setOpenManage] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(<></>);
  const [user, setUser] = useState({});
  const [access, setAccess] = useState({
    designation: "",
    role: "",
    projects: [],
  });
  const [options, setOptions] = useState([]);
  // constants
  const headLabels = [
    { id: 1, label: "Name" },
    { id: 2, label: "Email Address" },
    { id: 3, label: "Designation" },
    { id: 4, label: "Account Type" },
    { id: 5, label: "Projects", minWidth: "180px", width: "300px" },
    { id: 6, label: "Account Status" },
    { id: 7, label: "Actions" },
  ];

  const alias = {
    Manager: "Maintainer",
    "Billing Engineer": "Editor",
  };

  // handlers
  const handleTabChange = (_event, newValue) => {
    setPage(0);
    setActiveTab(newValue);
    const tabbedUsers = users.filter((user) =>
      newValue === 0
        ? user
        : newValue === 1
        ? user.status === "approved"
        : user.status === "pending"
    );
    setFilteredUsers(tabbedUsers);
    setLength(tabbedUsers.length);
  };

  const handleSearchChange = (event) => {
    setPage(0);
    setActiveTab(0);
    setSearch(event.target.value);
    var searchedUsers = users.filter((user) =>
      user.displayName.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredUsers(searchedUsers);
    setLength(searchedUsers.length);
  };

  const handleOpen = (user) => {
    setOpenManage(true);
    setUser(user);
    setAccess({
      role: user.role,
      projects: user.projects,
      designation: user.designation,
    });
  };

  const handleChangeStatus = async (approve, users) => {
    try {
      const response = await axiosInstance.put("/api/users/change-status", {
        ids: users.map((user) => user._id),
        approve: approve,
      });
      console.log(response.data);
      getUsers();
      setActiveTab(0);
      handleCloseDialog();
      enqueueSnackbar("User status updated", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error updating user status", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleOpenDialog = (users, approve, multi) => {
    if (multi) users = filteredUsers.filter((user) => users.includes(user._id));
    setDialogContent(
      <>
        <DialogTitle sx={{ mb: 2 }}>
          {approve
            ? "Create Account Confirmation"
            : "Deny for Account Creation"}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {approve
              ? "Are you sure you want to approve account creation request for"
              : "Are you sure you want to deny account creation request for"}
            {multi ? (
              <ul style={{ marginLeft: "1rem", marginTop: "1rem" }}>
                {users.map((user) => (
                  <li key={user._id}>{user.displayName}</li>
                ))}
              </ul>
            ) : (
              ` ${users[0].displayName}?`
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => handleChangeStatus(approve, users)}
          >
            {approve ? "Approve" : "Deny"}
          </Button>
          <Button variant="outlined" onClick={handleCloseDialog}>
            Cancel
          </Button>
        </DialogActions>
      </>
    );
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClose = () => {
    setOpenManage(false);
  };

  const handleSave = async () => {
    try {
      const response = await axiosInstance.put("/api/users/manage", {
        role: access.role,
        projects: access.projects,
        designation: access.designation,
        id: user._id,
      });
      console.log(response.data);
      getUsers();
      handleClose();
      enqueueSnackbar("User access updated", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getUsers = async () => {
    try {
      const response = await axiosInstance.get("/api/users");
      console.log(response.data);
      setUsers(response.data.users);
      setFilteredUsers(response.data.users);
      setPending(response.data.pending);
      setApproved(response.data.approved);
      setLength(response.data.users.length);
      setOptions(response.data.projects);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Helmet>
        <title>RABill | Users</title>
      </Helmet>
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Header title="Users" />
        <TextField
          label="Search by a user's name"
          value={search}
          onChange={handleSearchChange}
          fullWidth
        />
        <TableContainer
          component={Paper}
          sx={{ mt: 3, boxShadow: customShadows("light").card }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.grey[200],
              borderRadius: "16px 16px 0 0",
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              sx={{ padding: "0 0 0 18px" }}
            >
              <Tab
                icon={<Label color="info">{users.length}</Label>}
                label="All"
              ></Tab>
              <Tab
                icon={<Label color="success">{approved}</Label>}
                label="Approved"
              ></Tab>
              <Tab
                icon={<Label color="warning">{pending}</Label>}
                label="Pending"
              ></Tab>
            </Tabs>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              paddingLeft={1}
              py={1}
            >
              <Checkbox
                checked={selected.length === length}
                indeterminate={selected.length && selected.length < length}
                onChange={(_) =>
                  onSelectAllRows(selected.length === length, filteredUsers)
                }
              />
              <Typography>{selected.length} selected:</Typography>
              <Button
                color="inherit"
                variant="outlined"
                sx={{ ml: 5 }}
                size="small"
                disabled={!selected.length}
                onClick={() => handleOpenDialog(selected, true, true)}
              >
                Approve
              </Button>
              <Button
                color="inherit"
                variant="outlined"
                sx={{ ml: 1 }}
                size="small"
                disabled={!selected.length}
                onClick={() => handleOpenDialog(selected, false, true)}
              >
                Deny
              </Button>
            </Stack>
          </Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHeadCustom
              rowCount={length}
              headLabel={headLabels}
              numSelected={selected.length}
              onSelectAllRows={() =>
                onSelectAllRows(selected.length === length, filteredUsers)
              }
            />
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <TableRow key={item._id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selected.indexOf(item._id) !== -1}
                      onChange={(_) => onSelectRow(item._id)}
                    />
                  </TableCell>
                  <TableCell>{item.displayName}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.designation}</TableCell>
                  <TableCell>
                    {item.role === "user" ? "-" : alias[item.role]}
                  </TableCell>
                  <TableCell>
                    <ProjectChips projects={item.projects} />
                  </TableCell>
                  <TableCell>
                    <StatusButton status={item.status} />
                  </TableCell>
                  <TableCell>
                    {item.status === "approved" ? (
                      <Button
                        color="inherit"
                        size="small"
                        variant="outlined"
                        onClick={() => handleOpen(item)}
                      >
                        Manage Access
                      </Button>
                    ) : (
                      <Stack direction="row" spacing={2}>
                        <Button
                          color="inherit"
                          size="small"
                          variant="outlined"
                          onClick={() => handleOpenDialog([item], true, false)}
                        >
                          Approve
                        </Button>
                        {item.status !== "denied" && (
                          <Button
                            color="inherit"
                            size="small"
                            variant="outlined"
                            onClick={() =>
                              handleOpenDialog([item], false, false)
                            }
                          >
                            Deny
                          </Button>
                        )}
                      </Stack>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </Table>
          <TablePaginationCustom
            count={length}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            page={page}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </TableContainer>
      </Container>
      <ManageAccessDialog
        open={openManage}
        options={options}
        user={user}
        access={access}
        setAccess={setAccess}
        handleClose={handleClose}
        handleSave={handleSave}
      />
      <Dialog open={openDialog} handleClose={handleCloseDialog}>
        {dialogContent}
      </Dialog>
    </>
  );
}
