import { createSlice } from "@reduxjs/toolkit";
import * as uuid from "uuid";
import _ from "lodash";

function getUniqueId() {
  return uuid.v4();
}

const insert = (arr, index, newItems) => [
  ...arr.slice(0, index),

  ...newItems,

  ...arr.slice(index),
];

const mbRowsSlice = createSlice({
  name: "MBRows",
  initialState: {
    currMbIndex: "",
    rows: {},
    copyRowsIds: [],
  },
  reducers: {
    updateFromDb: (state, action) => {
      // add unique id to each row if not present already
      state.currMbIndex = action.payload.subtab;
      state.rows[action.payload.subtab] = action.payload.rows.map(
        (row, index) => {
          let updatedRow = state.rows?.[action.payload.subtab]?.[index] ?? {};

          updatedRow = JSON.parse(JSON.stringify(updatedRow));
          delete updatedRow?.id;

          if (!row.id) {
            return {
              ...row,
              id: getUniqueId(),
              ...updatedRow,
            };
          }
          
          return {
            ...row,
            ...updatedRow,
          };
        }
      );
    },
    setDefaultRows: (state, action) => {
      if(true) {
        state.currMbIndex = action.payload.mbVersion;
        state.rows[action.payload.mbVersion] = [
          { id: getUniqueId(), titleRow: true },
          { id: getUniqueId() },
          { id: getUniqueId() },
          { id: getUniqueId() },
          { id: getUniqueId() },
        ];
      }
    },
    copyRows: (state, action) => {
      state.copyRowsIds = action.payload;
    },
    clearSelectedRows: (state, action) => {
      state.rows[state.currMbIndex] = _.filter(
        state.rows[state.currMbIndex],
        (row) => !action.payload.includes(row.id)
      );
    },
    turnRowsToTitle: (state, action) => {
      state.rows[state.currMbIndex] = state.rows[state.currMbIndex].map(
        (row) => {
          if (action.payload.includes(row.id)) {
            return { ...row, titleRow: true };
          }
          return row;
        }
      );
    },
    pasteRowBelow: (state, action) => {
      const selectedRowIndex = state.rows[state.currMbIndex].findIndex(
        (row) => row.id === action.payload
      );
      const copiedRows = state.rows[state.currMbIndex].map((row) => {
        if (state.copyRowsIds.includes(row.id)) {
          return { ...row, id: getUniqueId() };
        }
        return undefined;
      });

      state.rows[state.currMbIndex] = insert(
        state.rows[state.currMbIndex],
        selectedRowIndex + 1,
        copiedRows.filter((row) => row)
      );
    },
    pasteRowAbove: (state, action) => {
      const selectedRowIndex = state.rows[state.currMbIndex].findIndex(
        (row) => row.id === action.payload
      );
      const copiedRows = state.rows[state.currMbIndex].map((row) => {
        if (state.copyRowsIds.includes(row.id)) {
          return { ...row, id: getUniqueId() };
        }
        return undefined;
      });

      state.rows[state.currMbIndex] = insert(
        state.rows[state.currMbIndex],
        selectedRowIndex,
        copiedRows.filter((row) => row)
      );
    },
    deleteRow: (state, action) => {
      state.rows[state.currMbIndex] = state.rows[state.currMbIndex].filter(
        (row) => row.id !== action.payload
      );
    },
    addRowAbove: (state, action) => {
      const selectedRowIndex = state.rows[state.currMbIndex].findIndex(
        (row) => row.id === action.payload
      );
      state.rows[state.currMbIndex] = insert(
        state.rows[state.currMbIndex],
        selectedRowIndex - 1,
        [{ id: getUniqueId() }]
      );
    },
    addRowBelow: (state, action) => {
      const selectedRowIndex = state.rows[state.currMbIndex].findIndex(
        (row) => row.id === action.payload
      );
      state.rows[state.currMbIndex] = insert(
        state.rows[state.currMbIndex],
        selectedRowIndex + 1,
        [{ id: getUniqueId() }]
      );
    },
    duplicateRow: (state, action) => {
      const selectedRowIndex = state.rows[state.currMbIndex].findIndex(
        (row) => row.id === action.payload
      );
      state.rows[state.currMbIndex] = insert(
        state.rows[state.currMbIndex],
        selectedRowIndex,
        [
          {
            ...state.rows[state.currMbIndex][selectedRowIndex],
            id: getUniqueId(),
          },
        ]
      );
    },
    updateRow: (state, action) => {
      state.rows[state.currMbIndex] = state.rows[state.currMbIndex].map(
        (row) => {
          if (row.id === action.payload.id) {
            return { ...row, [action.payload.field]: action.payload.value };
          }
          return row;
        }
      );
    },
    clearRowValue: (state, action) => {
      state.rows[state.currMbIndex] = state.rows[state.currMbIndex].map(
        (row) => {
          if (row[action.payload]) return { ...row, [action.payload]: "" };
          return row;
        }
      );
    },
    addBulkRows: (state, action) => {
      let bulkRows = [];
      for (let index = 0; index < action.payload; index++) {
        bulkRows.push({ id: getUniqueId() });
      }

      let currRows = state?.rows?.[state?.currMbIndex] ?? []
      state.rows[state.currMbIndex] = [
        ...currRows,
        ...bulkRows,
      ];
    },
    updateColumn: (state, action) => {
      state.rows[state.currMbIndex] = state.rows[state.currMbIndex].map(
        (row) => {
          return {
            ...row,
            [action.payload.column]: action.payload.value,
          };
        }
      );
    },
  },
});

export const {
  copyRows,
  updateFromDb,
  setDefaultRows,
  clearSelectedRows,
  turnRowsToTitle,
  pasteRowBelow,
  pasteRowAbove,
  deleteRow,
  addRowAbove,
  addRowBelow,
  duplicateRow,
  updateRow,
  updateColumn,
  clearRowValue,
  addBulkRows,
} = mbRowsSlice.actions;

export default mbRowsSlice.reducer;
