import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";

export const Header = ({ title, idPresent = true }) => {
  const { pathname } = useLocation();
  const [pathTree, setPathTree] = useState([]);
  function splitHistoryPath() {
    let pathTree = pathname
      .split("/")
      .filter((segment) => segment !== "")
      .map((segment, index, segments) => {
        const breadcrumb = {
          name: segment,
          path: segments.slice(0, index + 1).join("/"),
        };
        return breadcrumb;
      })
      .filter((item) => item.name !== "");

    // removes the last id from URL if it has :id in the end for breadcrumb
    if (idPresent) {
      pathTree.pop();
      pathTree[pathTree.length - 1].path = pathname;
    }
    setPathTree(pathTree);
  }
  useEffect(() => {
    splitHistoryPath();
  }, []);
  return (
    <Box marginBottom="4em">
      <Typography variant="h4" component="h1" paragraph>
        {title}
      </Typography>
      <Breadcrumbs separator="•" aria-label="breadcrumb">
        {pathTree.map((breadcrumb, idx) => {
          const { name, path } = breadcrumb;
          return (
            <Link
              key={idx}
              color={idx === pathTree.length - 1 ? "inherit" : "text.primary"}
              to={`/${path}`}
              component={RouterLink}
              replace
            >
              {name.charAt(0).toUpperCase() + name.slice(1)}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};
