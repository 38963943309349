import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

export default function ManageAccessDialog({
  open,
  handleClose,
  user,
  access,
  setAccess,
  handleSave,
  options,
}) {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle sx={{ borderBottom: "1px solid #919EAB3D", mb: 2 }}>
        Manage Access
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" mb={2}>
          Project access request from user:
        </Typography>
        <TextField value={user.requestNote} disabled fullWidth size="small" />
        <Typography variant="body1" mt={4} mb={1}>
          Designation
        </Typography>
        <TextField
          value={access.designation}
          onChange={(e) =>
            setAccess((prev) => ({ ...prev, designation: e.target.value }))
          }
          fullWidth
          size="small"
        />
        <Typography variant="body1" mt={4} mb={1}>
          Assign Account Type
        </Typography>
        <RadioGroup
          name="role"
          row
          role="radiogroup"
          value={access.role}
          onChange={(_e, val) => setAccess((prev) => ({ ...prev, role: val }))}
        >
          <FormControlLabel
            value="Billing Engineer"
            control={<Radio />}
            label="Editor"
          />
          <FormControlLabel
            value="Manager"
            control={<Radio />}
            label="Maintainer"
          />
        </RadioGroup>
        <Typography variant="body1" mt={4} mb={1}>
          Select to add projects to give user access
        </Typography>
        <Autocomplete
          multiple
          value={access.projects}
          onChange={(_e, val) =>
            setAccess((prev) => ({ ...prev, projects: val }))
          }
          options={options}
          getOptionDisabled={(option) =>
            access.projects.some((p) => p.id === option.id)
          }
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select projects" />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" color="secondary" onClick={handleSave}>
            Save Changes
          </Button>
          <Button color="inherit" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
