import { Button, alpha, useTheme } from "@mui/material";

export const StatusButton = ({ status }) => {
  const theme = useTheme();
  switch (status) {
    case "pending":
      return (
        <Button
          size="small"
          variant="outlined"
          color="warning"
          sx={{
            backgroundColor: alpha(theme.palette.warning["main"], 0.16),
            color: theme.palette.warning["dark"],
          }}
        >
          Pending
        </Button>
      );
    case "approved":
      return (
        <Button
          variant="outlined"
          size="small"
          color="success"
          sx={{
            backgroundColor: alpha(theme.palette.success["main"], 0.16),
            color: theme.palette.success["dark"],
          }}
        >
          Approved
        </Button>
      );
    case "denied":
      return (
        <Button
          variant="outlined"
          size="small"
          color="error"
          sx={{
            backgroundColor: alpha(theme.palette.error["main"], 0.16),
            color: theme.palette.error["dark"],
          }}
        >
          Denied
        </Button>
      );
    default:
      return (
        <Button
          variant="outlined"
          size="small"
          sx={{
            backgroundColor: "warning.main",
            color: "warning.contrastText",
          }}
        >
          Pending
        </Button>
      );
  }
};
