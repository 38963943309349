// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: icon("ic_user"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  projects: [
    icon("ic_banking"),
    icon("ic_booking"),
    icon("ic_calendar"),
    icon("ic_cart"),
    icon("ic_invoice"),
  ],
};

const navConfig = (recent = []) => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "General",
    items: [
      {
        title: "All Projects",
        path: PATH_DASHBOARD.projects.view,
        icon: ICONS.dashboard,
      },
      {
        title: "Users",
        path: PATH_DASHBOARD.users,
        icon: ICONS.ecommerce,
      },
    ],
  },
  {
    subheader: "Recent Projects",
    items: recent.map((project, idx) => ({
      title: project.code,
      path: PATH_DASHBOARD.projects.viewProject.replace(":id", project.code),
      icon: ICONS.projects[idx % ICONS.projects.length],
      children: [
        {
          title: "View Project",
          path: PATH_DASHBOARD.projects.viewProject.replace(
            ":id",
            project.code
          ),
        },
        {
          title: "Edit Project",
          path: PATH_DASHBOARD.projects.edit.replace(":id", project.code),
        },
        {
          title: "More",
          children: [
            {
              title: project.boq.revision === 0 ? "Upload BoQ" : "View BoQ",
              path:
                project.boq.revision === 0
                  ? null
                  : PATH_DASHBOARD.projects.boq.replace(":id", project.code),
              code: project.boq.revision === 0 ? project.code : null,
            },
            {
              title: "View Abstract",
              disabled: project.boq.revision === 0,
              path:
                project.boq.revision === 0
                  ? null
                  : PATH_DASHBOARD.projects.abstract.replace(
                      ":id",
                      project.code
                    ),
            },
            {
              title: "View RABill Summary",
              disabled: project.boq.revision === 0,
              path:
                project.boq.revision === 0
                  ? null
                  : PATH_DASHBOARD.projects.billSummary.replace(
                      ":id",
                      project.code
                    ),
            },
            {
              title: "View Certificate of Payments",
              disabled: project.boq.revision === 0,
              path:
                project.boq.revision === 0
                  ? null
                  : PATH_DASHBOARD.projects.cop.replace(":id", project.code),
            },
            {
              title: "View Measurement Book",
              disabled: project.boq.revision === 0,
              path:
                project.boq.revision === 0
                  ? null
                  : PATH_DASHBOARD.projects.mb.replace(":id", project.code),
            },
          ],
        },
      ],
    })),
  },
];

export default navConfig;
