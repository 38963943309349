import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "src/auth/useAuthContext";
import { RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import Iconify from "src/components/iconify/Iconify";
import { blackButton } from "src/utils/cssStyles";
import * as Yup from "yup";
import { PATH_AUTH } from "src/routes/paths";

export default function AuthRegisterForm({ visibility, setVisibility }) {
  const { register } = useAuthContext();

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await register(
        data.email,
        data.password,
        data.firstName,
        data.lastName,
        data.note
      );
      if (response.status === 200) {
        setVisibility((prev) => ({
          ...prev,
          pageTwo: "none",
          pageThree: "block",
        }));
      }
    } catch (error) {
      reset();
      setError("afterSubmit", {
        ...error,
        message: error.message,
      });
    }
  };

  const handleFormErrors = useCallback(() => {
    setVisibility((_) => ({
      pageOne: "block",
      pageTwo: "none",
      pageThree: "none",
    }));
  }, [setVisibility]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      handleFormErrors();
    }
  }, [errors, handleFormErrors]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ display: visibility.pageOne }}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <RHFTextField name="firstName" label="First name" />
          </Grid>
          <Grid item xs={6}>
            <RHFTextField name="lastName" label="Last name" />
          </Grid>
        </Grid>
        <RHFTextField name="email" label="Email address" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          fullWidth
          size="large"
          type="button"
          onClick={() =>
            setVisibility((prev) => ({
              ...prev,
              pageOne: "none",
              pageTwo: "block",
            }))
          }
          variant="contained"
          sx={blackButton}
        >
          Create Account
        </Button>
        <Typography
          variant="caption"
          color="inherit"
          underline="always"
          alignSelf="center"
        >
          By signing up, I agree to Terms of Use and Privacy Policy
        </Typography>
      </Stack>
      <Stack spacing={3} sx={{ display: visibility.pageTwo }}>
        <Typography variant="body2">
          You'll be notified when your account creation request is confirmed by
          the admin on your email address
        </Typography>
        <Typography variant="body2">
          You may enter project you want to request access to below
        </Typography>
        <RHFTextField
          name="note"
          placeholder="Note for admin"
          multiline
          rows={3}
          helperText={
            <Stack direction="row" spacing={1} alignItems="center">
              <Iconify icon="jam:info-f" />
              <Typography variant="caption">Note for admin to view</Typography>
            </Stack>
          }
          fullWidth
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitSuccessful || isSubmitting}
          sx={blackButton}
        >
          Send
        </LoadingButton>
      </Stack>
      <Stack spacing={3} sx={{ display: visibility.pageThree }}>
        <Typography variant="body2">
          You'll be notified when your account creation request is confirmed by
          the admin on your email address
        </Typography>
        <Button
          fullWidth
          LinkComponent={RouterLink}
          to={PATH_AUTH.login}
          variant="contained"
          size="large"
          sx={blackButton}
        >
          Back to Login
        </Button>
      </Stack>
    </FormProvider>
  );
}
