import { Box, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import LoginLayout from "src/layouts/login/LoginLayout";
import { PATH_AUTH } from "src/routes/paths";
import AuthRegisterForm from "./AuthRegisterForm";
import { useState } from "react";

export default function Register() {
  const [visibility, setVisibility] = useState({
    pageOne: "block",
    pageTwo: "none",
    pageThree: "none",
  });
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
        <Box sx={{ display: visibility.pageOne, padding: "0px" }}>
          <Typography variant="h4">Get Started</Typography>
          <Stack direction="row" spacing={0.5}>
            <Typography variant="body2">Already have an account?</Typography>

            <Link
              to={PATH_AUTH.login}
              component={RouterLink}
              variant="subtitle2"
            >
              Sign In
            </Link>
          </Stack>
        </Box>
        <Box
          sx={{
            display:
              visibility.pageTwo === "block"
                ? visibility.pageTwo
                : visibility.pageThree,
          }}
        >
          <Typography variant="h4">Account Creation Pending</Typography>
        </Box>
      </Stack>
      <AuthRegisterForm visibility={visibility} setVisibility={setVisibility} />
    </LoginLayout>
  );
}
