// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: "/login",
  register: "/register",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  projects: {
    view: path(ROOTS_DASHBOARD, "/projects"),
    create: path(ROOTS_DASHBOARD, "/projects/create"),
    edit: path(ROOTS_DASHBOARD, "/projects/edit/:id"),
    viewProject: path(ROOTS_DASHBOARD, "/projects/view/:id"),
    boq: path(ROOTS_DASHBOARD, "/projects/boq/:id"),
    mb: path(ROOTS_DASHBOARD, "/projects/measurement-book/:id"),
    billSummary: path(ROOTS_DASHBOARD, "/projects/bill-summary/:id"),
    cop: path(ROOTS_DASHBOARD, "/projects/cop/:id"),
    abstract: path(ROOTS_DASHBOARD, "/projects/abstract/:id"),
  },
  users: path(ROOTS_DASHBOARD, "/users"),
  error: path("/404"),
};
